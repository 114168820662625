import { useEffect, useState } from "react";
import { Box, Card, Typography, Button, Chip } from "@mui/material";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { fetchComplaintList } from "../../api";
import { getStatusColor } from "../complaint-details/constants";
import "./ComplaintsTracker.css";
import { SummaryDialog } from "./components";
import { AgeChip } from "../../components";

function getRowId(row: any) {
  return row.complaint_id;
}

const ComplaintsTracker = () => {
  const navigate = useNavigate();
  const [complaintsList, setComplaintsList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastRefreshed, setLastRefreshed] = useState<Date | null>(null);

  const [open, setOpen] = useState(false);
  const [selectedSummary, setSelectedSummary] = useState('');

  const handleOpenDialog = (summary: string) => {
    setSelectedSummary(summary);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    const cachedData = localStorage.getItem('complaintsList');
    const cachedTimestamp = localStorage.getItem('lastRefreshed');
    if (cachedData) {
      setComplaintsList(JSON.parse(cachedData));
    } else {
      fetchComplaintsData();
    }
    if (cachedTimestamp) {
      setLastRefreshed(new Date(cachedTimestamp));
    }
  }, []);

  useEffect(() => {
    if (complaintsList?.length) {
      setTableData(complaintsList);
    }
  }, [complaintsList]);

  const fetchComplaintsData = async () => {
    setLoading(true);
    const response = await fetchComplaintList();
    if (response) {
      setComplaintsList(response);
      localStorage.setItem('complaintsList', JSON.stringify(response));
      const now = new Date();
      setLastRefreshed(now);
      localStorage.setItem('lastRefreshed', now.toISOString());
    }
    setLoading(false);
  };

  const handleRefetch = () => {
    fetchComplaintsData();
  };

  const formatLastRefreshed = (date: Date): string => {
    const now = new Date();
  
    const isSameDay = now.toDateString() === date.toDateString();
    const isYesterday = new Date(now.getTime() - 24 * 60 * 60 * 1000).toDateString() === date.toDateString();
    
    if (isSameDay) {
      return `today at ${date.toLocaleTimeString()}`;
    } else if (isYesterday) {
      return `yesterday at ${date.toLocaleTimeString()}`;
    } else {
      const dayOfWeek = date.toLocaleDateString(undefined, { weekday: 'long' });
      return `${dayOfWeek} at ${date.toLocaleTimeString()}`;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "complaint_id",
      headerName: "Complaint ID",
      flex: 0.5,
      minWidth: 100
    },
    {
      field: "customer_name",
      headerName: "Customer Name",
      flex: 0.7,
      renderCell: (params) => params.row.customer_details?.customer_name ?? "N/A"
    }, {
      field: "customer_id",
      headerName: "Customer ID",
      flex: 0.6
    }, {
      field: "date_created",
      headerName: "Created",
      flex: 0.8,
      renderCell: (params) => (
        <AgeChip dateCreated={params?.row?.date_created} />
      )
    }, {
      field: "complaint_status",
      headerName: "Status",
      flex: 0.7,
      renderCell: (params) => (
        <Chip size="small" label={params.row.complaint_status ?? "N/A"} color={getStatusColor(params.row.complaint_status)} />
      )
    }, {
      field: "complaint_categorisation",
      headerName: "Category",
      flex: 0.7,
      renderCell: (params) => (
        <Chip size="small" label={params.row.complaint_categorisation?.Decision ?? "N/A"} />
      )
    }, {
      field: "action",
      headerName: "Actions",
      flex: 1.4,
      minWidth: 250,
      renderCell: (params) => (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="flex-start" gap={2}>
          <Button
            onClick={() => navigate(`/complaint-tracker/${params.row.complaint_id}`)}
            variant="contained"
            size="small"
            style={{
              textTransform: "none",
            }}>Open details</Button>

          <Button
            onClick={() => handleOpenDialog(params.row.complaint_summary)}
            variant="outlined"
            size="small"
            style={{
              textTransform: "none"
            }}>
            Summary glance
          </Button>
        </Box>
      )
    }
  ];

  return (
    <div>
      <Typography variant="h1" marginBottom={"12px"} gutterBottom>
        Complaints Tracker
      </Typography>
      <Box display="flex" alignItems="center">
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={handleRefetch}
          disabled={loading}>
          {loading ? 'Loading...' : 'Refresh data'}
        </Button>
        {lastRefreshed && (
          <Typography variant="caption" style={{ marginLeft: '10px' }}>
            Last refreshed {formatLastRefreshed(lastRefreshed)}
          </Typography>
        )}
      </Box>
      <Card
        variant="outlined"
        style={{
          borderRadius: "8px",
          marginTop: "16px"
        }}
        className="complaintstable">
        <DataGrid
          loading={loading}
          disableRowSelectionOnClick
          getRowId={getRowId}
          rows={tableData}
          columns={columns}
          initialState={{
            sorting: { sortModel: [{ field: 'date_created', sort: 'desc' }] },
            pagination: {
              paginationModel: {
                page: 0,
                pageSize: 10
              }
            }
          }}
          pageSizeOptions={[5, 10]}
          slots={{
            toolbar: GridToolbar
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              printOptions: {
                disableToolbarButton: true
              },
              csvOptions: {
                disableToolbarButton: true
              }
            },
            columnHeaders: {
              style: {
                outline: 'none',
              },
            },
            cell: {
              style: {
                outline: 'none',
              },
            },

          }}
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          sx={{
            boxShadow: 0,
            border: 0
          }} />
      </Card>

      <SummaryDialog open={open} selectedValue={selectedSummary} onClose={handleCloseDialog} />
    </div>
  );
};

export default ComplaintsTracker;