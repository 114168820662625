import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Tabs, Tab, Typography, Chip, Button } from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import Transcript from "./components/Triage";
import { fetchSingleComplaint } from "../../api";
import { ComplaintDetailsTabs, getStatusColor } from "./constants";
import { CaseDetails, CustomTabPanel, Findings, Response } from "./components";
import FileUpload from "./components/FileUpload";
import LoadingComponent from "../../components/loading";
import CaseSpeedDial from "./components/CaseSpeedDial";
import Notification from "../../components/Notification";
import useNotification from "../../hooks/useNotification";

const ComplaintDetails = () => {
  const { id } = useParams<{ id: string }>();
  const [complaint, setComplaint] = useState<any | null>(null);
  const [transcript, setTranscript] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>("");
  const [value, setValue] = useState<any>(0);

  const { notification, showNotification, closeNotification } = useNotification();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const fetchData = async () => {
    setLoading(true);
    showNotification("Fetching data...", "info");
    try {
      if (!id) {
        return;
      }
      const response = await fetchSingleComplaint(id);
      if (response.complaint_raw !== "N/A") {
        const transcriptRaw = JSON.parse(response.complaint_raw);
        setTranscript(transcriptRaw);
      } else {
        setTranscript(response.complaint_raw);
      }
      setComplaint(response);
      setLoading(false);
      showNotification("Data fetched successfully!", "success");
    } catch (error) {
      setLoading(false);
      if (error instanceof Error) {
        setError(error.message);
        showNotification(error.message, "error");
      } else {
        setError('An error occurred while fetching the data. Please try again.');
        showNotification('An error occurred while fetching the data. Please try again.', "error");
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  if (loading) return <LoadingComponent />;
  if (error) return <div>{error}</div>;

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1} mb={2} justifyContent="space-between">
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="h3">
            Case ID: {id}
          </Typography>
          <Chip label={complaint.complaint_status} size="small" color={getStatusColor(complaint.complaint_status)} />
        </Box>
        <Button 
          size="small"
          variant="outlined" 
          startIcon={<RefreshIcon />} 
          onClick={fetchData}
        >
          Refresh
        </Button>
      </Box>
      <Box sx={{ width: "100%", bgcolor: "#F3F3F3", borderRadius: "8px"}}>
        <Tabs value={value} onChange={handleChange}>
          {ComplaintDetailsTabs.map((tab: any, index: number) => (
            <Tab sx={{textTransform: 'none'}} key={tab.value} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {complaint ? (
        <Box mt={2}>
          <CustomTabPanel value={value} index={0}>
            <CaseDetails complaint={complaint} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <FileUpload complaintId={complaint.complaint_id} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Transcript transcript={transcript} complaint={complaint} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Findings complaint={complaint} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Response complaint={complaint} complaintId={complaint.complaint_id} />
          </CustomTabPanel>
        </Box>
      ) : (
        <p>No complaint found with ID: {id}</p>
      )}
      <CaseSpeedDial complaint={complaint} showNotification={showNotification} onAddNotesSuccess={fetchData} />
      <Notification
        open={notification.open}
        message={notification.message}
        severity={notification.severity}
        onClose={closeNotification}
      />
    </Box>
  );
};

export default ComplaintDetails;