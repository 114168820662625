import { useState, useEffect } from "react";
import {
  Card,
  Grid,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Link,
  Alert
} from "@mui/material";
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import { fetchAuthSession } from "aws-amplify/auth";
import { BASE_ENGINE_API_URL } from "../../../api";
import React from "react";
import Notification from "../../../components/Notification";
import useNotification from "../../../hooks/useNotification"; // Import the custom hook

async function uploadFile(complaintId: string, file: File, handleOpenDialog: () => void) {
  try {
    console.log("uploadFile called with file:", file);
    if (file.type !== 'audio/mpeg') {
      console.error("Invalid file type. Only .mp3 files are allowed.");
      handleOpenDialog();
      return null;
    }

    console.log("Fetching Auth Session");
    var cognitoTokens = (await fetchAuthSession()).tokens;
    let identityToken = cognitoTokens?.idToken?.toString();
    console.log("Identity Token: ", identityToken);

    console.log(complaintId);
    const requestBody = {
      type: "upload_audiorecording",
      complaint_id: complaintId
    };

    const presignResponse = await fetch(`${BASE_ENGINE_API_URL}/presign`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${identityToken}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(requestBody)
    });

    if (!presignResponse.ok) {
      throw new Error(`Failed to get presigned URL. Status: ${presignResponse.status}`);
    }

    let presignedUrl = await presignResponse.text();
    presignedUrl = presignedUrl.replace(/['"]+/g, ""); // Remove any quotes around the URL
    console.log("Cleaned Presigned URL:", presignedUrl);

    const uploadResponse = await fetch(presignedUrl, {
      method: "PUT",
      headers: {
        "Content-Type": file.type
      },
      body: file
    });

    if (!uploadResponse.ok) {
      const errorText = await uploadResponse.text();
      throw new Error(`Failed to upload file. Status: ${uploadResponse.status}, Response: ${errorText}`);
    }

    console.log("File uploaded successfully!");
    return "File uploaded successfully!" + uploadResponse.url;
  } catch (error) {
    console.error("Error uploading file:", error);
    return null;
  }
}

interface Props {
  complaintId: string;
}

export default function FileUpload({ complaintId }: Props) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const clearFile = () => {
    setSelectedFile(null);
  };
  const [fileName, setFileName] = useState<String | null>("");

  const [uploadConfirmed, setUploadConfirmed] = useState("");
  const [uploadUrl, setUploadUrl] = useState<string | null>(null);
  const [open, setOpen] = useState(false);

  const { notification, showNotification, closeNotification } = useNotification();

  useEffect(() => {
    console.log(complaintId);
  }, [complaintId]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("handleFileChange called");
    const files = event.target.files;
    if (files && files[0]) {
      const file = files[0];
      console.log("File selected:", file);
      setSelectedFile(file);
      setFileName(file.name);
    } else {
      console.log("No file selected");
    }
  };

  const handleUploadClick = async () => {
    if (!selectedFile)
      return;
    showNotification('Your file upload has started.', 'info');

    const confirmation = await uploadFile(complaintId, selectedFile, handleOpenDialog);
    if (confirmation) {
      console.log("Upload confirmed:", confirmation);
      setUploadConfirmed(confirmation);
      setUploadUrl(confirmation);
      showNotification('Your file upload was successful.', 'success');
      clearFile();
    } else {
      showNotification('Failed to upload file.', 'error');
    }
  };

  const handleOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // Hardcoded file data for demo purposes - future scope is develop an API to fetch this data or include in the complaint data
  const files = [
    {
      name: fileName?.toString(),
      type: "audio/mpeg",
      date: new Date().toISOString(),
      url: uploadUrl?.toString()
    }
  ];

  return (
    <Grid container justifyContent="center" alignItems="center" maxWidth={'md'}>
      <Grid item md={10} marginLeft={'2px'}>
      <Card
                        variant="outlined"
                        style={{
                        padding: '24px',
                    }}>
          <Grid item md={12}>
            <Typography variant="h6" gutterBottom>
              Upload a supporting file
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body2" gutterBottom>
              Please upload a file that contains details about the customer complaint. For
              example, a call recording, email or scanned letter. You can upload MP3, PNG or
              PDF files. The max file size is 10MB.
            </Typography>
          </Grid>
          <Grid
            sx={{
              width: '100%',
              flexGrow: 1,
              marginTop: "16px",
              border: "1px dashed rgba(0, 0, 0, 0.12)"
            }}
            item
            container
            justifyContent="center"
            alignItems="center"
            py={5}
            md={12}>
            {!selectedFile && (
              <Button
                style={{}}
                component="label"
                role={undefined}
                variant="outlined"
                tabIndex={-1}
                size="large">
                <FolderOpenIcon
                  sx={{
                    mr: 1
                  }} />Select a file
                <input
                  type="file"
                  style={{
                    display: "none"
                  }}
                  onChange={handleFileChange} />
              </Button>
            )}
            {selectedFile && fileName && (
              <Alert icon={false} severity="info">
                <b>Selected file:
                </b>
                {fileName}
                <IconButton onClick={clearFile}>
                  <RemoveCircleIcon />
                </IconButton>
              </Alert>
            )}
          </Grid>
          <Grid item md={12} mt={2} container justifyContent="flex-end">
            {selectedFile && fileName && (
              <Button
                size="medium"
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={< FileUploadIcon />}
                onClick={handleUploadClick}>
                Upload
              </Button>
            )}
          </Grid>
        </Card>
      </Grid>

      {/* Notifications, alerts and dialog boxes  */}
      {/* invalid file type dialog alert */}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
          <Typography variant="h5">Invalid file type</Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body2">
              Only .mp3 files are allowed.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reusable Notification component */}
      <Notification
        open={notification.open}
        message={notification.message}
        onClose={closeNotification}
        severity={notification.severity} />

      {uploadConfirmed && (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          maxWidth={'md'}
                    mt={2}>
                    <Grid item md={10} marginLeft={'2px'}>
                    <Card
                        variant="outlined"
                        style={{
                        padding: '24px',
                        
                    }}>
                            <Grid item md={12}>
                                <Typography variant="h6" gutterBottom>
                                    Attached files
                                </Typography>
                                <Divider
                                    sx={{
                                    mb: 0
                                }}/>
                            </Grid>
                            <Grid
                                sx={{
                                width: '100%',
                                flexGrow: 1
                            }}
                                item
                                container
                                justifyContent="center"
                                alignItems="center"
                                py={2}
                                md={12}>
                                <TableContainer>
                                    <Table
                                        sx={{
                                        minWidth: 650
                                    }}
                                        aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>File Name</TableCell>
                                                <TableCell align="right">File Type</TableCell>
                                                <TableCell align="right">Date Uploaded</TableCell>
                                                <TableCell align="right">URL</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {files.map((file) => (
                                                <TableRow key={file.name}>
                                                    <TableCell component="th" scope="row">
                                                        {file.name}
                                                    </TableCell>
                                                    <TableCell align="right">{file.type}</TableCell>
                                                    <TableCell align="right">{file.date}</TableCell>
                                                    <TableCell align="right">
                                                        <Link href={file.url}>Source</Link>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}