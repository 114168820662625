import { useState, useEffect } from "react";
// import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
// import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
// import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
// import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
// import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import DetailsCard from "./detailscard";
import { TranscriptEntryTimings, TranscriptElementProps, TranscriptTextProps, TranscriptWordProps } from "../../../interfaces/interfaces";
import { Typography } from "@mui/material";


// const SentimentVerySatisfiedIcon = SentimentSatisfiedAltIcon;

// // Returns the correct sentiment face icon with colour
// function getSentiment(confidence: any) {
//     /* Satisafction ranges:
//          V.Dis = 0-0.2
//          Dis = 0.2-0.4
//          Neu = 0.4-0.6
//          Sat = 0.6-0.8
//          V.Sat = 0.8-1 */
//     let componentToRender;

//     if (confidence < 0.2) {
//         componentToRender = <SentimentVeryDissatisfiedIcon fontSize="large" className="v-unsat-sentiment" />;
//     } else if (confidence >= 0.2 && confidence < 0.4) {
//         componentToRender = <SentimentDissatisfiedIcon fontSize="large" className="unsat-sentiment" />;
//     } else if (confidence >= 0.4 && confidence < 0.6) {
//         componentToRender = <SentimentNeutralIcon fontSize="large" className="neut-sentiment" />;
//     } else if (confidence >= 0.6 && confidence < 0.8) {
//         componentToRender = <SentimentSatisfiedIcon fontSize="large" className="sat-sentiment" />;
//     } else if (confidence >= 0.8 && confidence <= 1) {
//         componentToRender = <SentimentVerySatisfiedIcon fontSize="large" className="v-sat-sentiment" />;
//     } else {
//         console.log("Your confidence is to high!");
//     }

//     return <span>{componentToRender}</span>;
// }

//Creates and updates each span element depending on audio time for highlighting purposes
const TranscriptSpan: React.FC<TranscriptWordProps> = ({ data, index, currentTime }) => {
    const [updatedData, setUpdatedData] = useState<any>();
    const [hasData, setHasData] = useState(false);

    useEffect(() => {
        const updateData = () => {
            let className = '';
            const updated = data.map((element: TranscriptEntryTimings) => {
                //To highlight needs to be less than currentPlayTime
                const isCurrentTimeInRange = parseFloat(element.start_time) <= currentTime;
                // And element must be within half second current time
                const isElementBeforeTime = parseFloat(element.start_time) <= (currentTime - 0.3);
                const isElementAheadTime = parseFloat(element.start_time) > (currentTime + 0.05);
                //prevEndTime = parseFloat(element.end_time)
                if(isCurrentTimeInRange && !isElementBeforeTime && !isElementAheadTime){
                    className = 'highlighted';
                }
                if(isElementAheadTime){
                    className = '';
                }

                return { ...element, className };
            });
            setHasData(true);
            setUpdatedData(updated);
        };
        updateData();
    }, [data, currentTime]);

    return (
        <p>
            {hasData ? (
                updatedData.map((word: any | null, index: number) => (
                    <span
                        key={index}
                        data-start={word.start_time} data-end={word.end_time}
                        className={word.className}
                    >{word.transcript} </span>
                ))
            ) : (
                <>Loading...</>
            )}
        </p>
    )
}

// Creates the transcript paragraph of the current speaker
const TranscriptText: React.FC<TranscriptTextProps> = ({ data, index, currentTime }) => {
    const [updatedData, setUpdatedData] = useState<TranscriptEntryTimings[]>([]);
    //console.log("data", data)
    useEffect(() => {
        const updateData = () => {
            const updated = data.conversation_timings.map((element: TranscriptEntryTimings) => {
                return { ...element };
            });
            setUpdatedData(updated);
        };
        updateData();
    }, [data, currentTime]);
    return (
        <TranscriptSpan key={index} currentTime={currentTime} index={index} data={updatedData} />
    )
}

// // Creates the Speaker Entry Element with sentiment and icons etc..
// const TranscriptEntryElement: React.FC<TranscriptElementProps> = ({ data, index, currentTime }) => {

//     return (
//         <DetailsCard transcriptType={true} key={index}>
//             <div className="transcript-part">
//                 <div className="transcript-speaker-icon">
//                     <p>
//                         {index % 2 === 0 ? (
//                             <AccountBalanceIcon  fontSize="small"/>
//                         ) : (
//                             <PersonIcon  fontSize="small"/>
//                         )}{" "}
//                     </p>
//                 </div>
//                 <div className="transcript-text">
//                     {data.map((part, index) => (
//                         <Typography variant="body2">
//                             <TranscriptText key={index} currentTime={currentTime} index={index} data={part} />
//                         </Typography>
//                     ))}
//                 </div>
//             </div>
//             <div className="transcript-part-adj">
//                 {/* <div className="transcript-part-adj">
//                     <p>{getSentiment(data[0].confidence)}</p>
//                 </div> */}
//                 <div>
//                 <Typography variant="caption">
//                         Timestamp: {data[0].start_time}s - {data[0].end_time}s
//                     </Typography>
//                 </div>
//             </div>
//         </DetailsCard>
//     )
// };

// Creates the Speaker Entry Element with sentiment and icons etc..
const TranscriptEntryElement: React.FC<TranscriptElementProps> = ({ data, index, currentTime }) => {

    const speakerClass = index % 2 === 0 ? "speaker1" : "speaker2";

    return (
        <DetailsCard transcriptType={true} key={index}>
            <div className={`transcript-part ${speakerClass}`}>
                <div className="transcript-speaker-icon">
                    <p>
                        {index % 2 === 0 ? (
                            <AccountBalanceIcon  fontSize="small"/>
                        ) : (
                            <PersonIcon  fontSize="small"/>
                        )}{" "}
                    </p>
                </div>
                <div className="transcript-text">
                    {data.map((part, index) => (
                        <Typography variant="body2">
                            <TranscriptText key={index} currentTime={currentTime} index={index} data={part} />
                        </Typography>
                    ))}
                </div>
            </div>
            <div className="transcript-part-adj">
                {/* <div className="transcript-part-adj">
                    <p>{getSentiment(data[0].confidence)}</p>
                </div> */}
                <div>
                <Typography variant="caption">
                        Timestamp: {data[0].start_time}s - {data[0].end_time}s
                    </Typography>
                </div>
            </div>
        </DetailsCard>
    )
};

export default TranscriptEntryElement;