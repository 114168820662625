import React from 'react';

import {fetchUserAttributes} from "aws-amplify/auth";

interface UserAttributeProps {
  attribute: 'firstName' | 'lastName' | 'fullName' | 'username' | 'email';
}

const UserAttribute: React.FC<UserAttributeProps> = ({ attribute }) => {
  const [attributeValue, setAttributeValue] = React.useState<string | any>("");

  React.useEffect(() => {
    const getUserAttributes = async () => {
      try {
        const attributes = await fetchUserAttributes();
        let value: string | undefined;
        switch (attribute) {
          case 'firstName':
            value = attributes.given_name;
            break;
          case 'lastName':
            value = attributes.family_name;
            break;
          case 'fullName':
            value = `${attributes.given_name} ${attributes.family_name}`;
            break;
          case 'username':
            value = attributes.username;
            break;
          case 'email':
            value = attributes.email;
            break;
          default:
            value = undefined;
            break;
        }
        setAttributeValue(value);
      } catch (error) {
        console.log("Error getting user attributes:", error);
      }
    };

    getUserAttributes();
  }, [attribute]);

  return (
    <div>
      {attributeValue}
    </div>
  );
};

export default UserAttribute;