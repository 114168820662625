import { useLocation, Link } from "react-router-dom";
import { CrumbNameMapping } from "../../routes";
import "./breadcrumbs.css";

export function Breadcrumbs() {
  const location = useLocation();

  let currentLink = "";
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += `/${crumb}`;

      return (
        <div className="crumb" key={crumb}>
          <Link to={currentLink}>{CrumbNameMapping[crumb] || crumb}</Link>
        </div>
      );
    });

  // Include the base crumb
  const allCrumbs = [
    <div className="crumb" key="home">
      <Link to="/">{CrumbNameMapping[""]}</Link>
    </div>,
    ...crumbs,
  ];

  return (
    <>
      {allCrumbs?.length > 1 && (
        <div className="breadcrumbs-container">
          <div className="breadcrumbs">{allCrumbs}</div>
        </div>
      )}
    </>
  );
}