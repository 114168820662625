import { useState } from "react";
import { getCurrentUser } from "aws-amplify/auth";
import { Box, Typography, Divider, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { createNewComplaint, fetchCustomer } from "../../api";
import { CustomerData, NewComplaintDataRequest } from "../../interfaces";
import { isEmptyObject } from "../../constants/utilities";
import useNotification from "../../hooks/useNotification";
import Notification from "../../components/Notification";
import { SubmitNotes } from "./components/SubmitNotes";
import { CustomerSearch } from "../complaints-tracker/components/CustomerSearch";

export interface NewCompFormData {
  customerId: string;
  customerName: string;
  customerFirstName: string;
  customerLastName: string;
  customerAddress: string;
  customerAddressStreet: string;
  customerAddressTown: string;
  customerPostcode: string;
  customerPhoneNumber: string;
  customerEmail: string;
  customerDob: string;
  complaintNotes: string;
}

function NewComplaint() {
  const navigate = useNavigate();
  const [fetchingCustomerDetails, setFetchingCustomerDetails] =
    useState<boolean>(false);
  const [data, setData] = useState<CustomerData | undefined>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { notification, showNotification, closeNotification } =
    useNotification();

  async function newComplaint(form: NewCompFormData) {
    setLoading(true);
    const { username, userId } = await getCurrentUser();
    const complaintData: NewComplaintDataRequest = {
      userDetails: {
        user_id: userId,
        user_name: username,
      },
      customer_id: form.customerId,
      customer_details: {
        customer_name: form.customerName,
        customer_first_name: form.customerFirstName,
        customer_last_name: form.customerLastName,
        customer_address: form.customerAddress,
        customer_address_street: form.customerAddressStreet,
        customer_address_town: form.customerAddressTown,
        customer_postcode: form.customerPostcode,
        customer_phone: form.customerPhoneNumber,
        customer_email: form.customerEmail,
        customer_dob: form.customerDob,
      },
      notes: [form.complaintNotes],
    };
    return createNewComplaint(complaintData);
  }

  const handleChange = (customerId: string) => {
    setError(null);
    if (customerId) {
      fetchData(customerId);
    }
  };

  const fetchData = async (customerId: string) => {
    setFetchingCustomerDetails(true);
    try {
      const response: any = await fetchCustomer(customerId);
      setData(response);
      setError(null);
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError(
          "An error occurred while fetching the customerId. Please try again."
        );
        showNotification(
          "An error occurred while fetching the customerId. Please try again.",
          "error"
        );
      }
    } finally {
      setFetchingCustomerDetails(false);
    }
  };

  const onSubmitClick = async (notes: string) => {
    if (!data) return;
    setLoading(true);
    const form = {
      customerId: data?.customer_id,
      customerName: data?.customer_first_name + " " + data?.customer_last_name,
      customerFirstName: data?.customer_first_name,
      customerLastName: data?.customer_last_name,
      customerAddressStreet: data?.customer_address_street,
      customerAddressTown: data?.customer_address_town,
      customerPostcode: data?.customer_postcode,
      customerAddress:
        data?.customer_address_street +
        ", " +
        data?.customer_address_town +
        ", " +
        data?.customer_postcode,
      customerPhoneNumber: data?.customer_phone,
      customerEmail: data?.customer_email,
      customerDob: data?.customer_dob,
      complaintNotes: notes,
    };
    try {
      const newComplaintId = await newComplaint(form);
      setLoading(false);
      if (newComplaintId) {
        showNotification("Complaint submitted successfully", "success");
        setTimeout(() => {
          navigate(`/complaint-tracker/${newComplaintId.split('"').join("")}`);
        }, 2000);
      } else {
        throw new Error("Failed to create new complaint");
      }
    } catch (error) {
      setLoading(false);
      showNotification("Failed to create new complaint", "error");
    }
  };
  return (
    <>
      <Typography variant="h1" gutterBottom>
        Create a new complaint
      </Typography>

      <Notification
        open={notification.open}
        message={notification.message}
        onClose={closeNotification}
        severity={notification.severity}
      />
      {error && (
        <Notification
          open={notification.open}
          message={notification.message}
          onClose={closeNotification}
          severity={notification.severity}
        />
      )}
      <Alert severity="warning">
        <Typography variant="body2" gutterBottom>
          This is for demonstration purposes only. Implementation would involve integration with a CRM and existing complaints management system.
        </Typography>
      </Alert>
      <Box mt={2} mb={3}>
      <Typography variant="h3" gutterBottom>
         Select a customer
            </Typography>
      <Divider />
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <CustomerSearch
          data={data}
          fetchingCustomerDetails={fetchingCustomerDetails}
          handleChange={handleChange}
        ></CustomerSearch>
      </Box>
      
      
      {!fetchingCustomerDetails && data && !isEmptyObject(data) && (
        <>
        <Box mt={4} mb={3}>
        <Typography variant="h3" gutterBottom>
          Open a new complaint for {data.customer_first_name} {data.customer_last_name}
        </Typography>
        <Typography variant="body2" gutterBottom mb={2}>
            Fill in the notes below to proceed with opening a new complaint
                               
                               </Typography>
        <Divider />
      </Box>
        <Box sx={{ mt: 5, maxWidth: "650px" }}>
         
          <SubmitNotes
            loading={loading}
            onNotesSubmit={onSubmitClick}
          />
        </Box>
        </>
      )}
    </>
  );
}

export default NewComplaint;
