import {
  Box,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";

interface Props {
  // title: string;
  loading?: boolean;
  onNotesSubmit: Function;
}

export function SubmitNotes({ loading, onNotesSubmit }: Props) {
  const [notes, setNotes] = useState("");
  const handleNotesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNotes(e.target.value);
  };

  return (
    <Box>
      <Box
        sx={{
          mb: "32px",
        }}
      >
        {/*<Typography variant="h3" gutterBottom>
          {title}{" "}
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please provide as much detail as possible as this will help both the
          AI model as well as the Complaints Handling team.
        </Typography> */}
        <Box
          sx={{
            my: "24px",
          }}
        >
          <TextField
            required
            fullWidth
            id="outlined-multiline-flexible"
            multiline
            rows={7}
            label="Details"
            onChange={handleNotesChange}
            value={notes}
            variant="filled"
          />
        </Box>
        <Button
          onClick={() => onNotesSubmit(notes)}
          size="large"
          variant="contained"
          disabled={!notes && loading}
        >
          {loading && (
            <CircularProgress
              style={{ color: "white", marginRight: 8 }}
              size={16}
            />
          )}
          Create new complaint
        </Button>
      </Box>
    </Box>
  );
}
