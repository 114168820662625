export const Steps = [
  {
    label: "Upload",
    description:
      "In the first step, the customer uploads their complaint, which could be in the form of a call recording, an email, a letter, or a chatbot transcript. This step is crucial as it initiates the complaint handling process, providing the necessary data for further analysis. The system accepts various formats to ensure that all types of complaints can be captured and processed effectively.",
    image: "/images/step1.png",
  },
  {
    label: "Ingest & Process",
    description:
      "Once the complaint is uploaded, it is ingested and processed by the system. This involves converting the data into a structured format that can be analysed. For instance, audio recordings are transcribed into text, emails and letters are scanned for key information, and chatbot transcripts are parsed to identify the core issues. This step ensures that the complaint data is ready for detailed analysis.",
    image: "/images/step2.png",
  },
  {
    label: "Analyse",
    description:
      "The processed complaint data is then analysed using GenAI (Generative Artificial Intelligence). This involves programmatic analysis where the AI identifies patterns, categorises the complaint, and suggests potential resolutions. The analysis is guided by prompt engineering and compliance with relevant regulations, ensuring that the decisions made are both accurate and lawful. This step leverages advanced AI techniques to provide insights and recommendations.",
    image: "/images/step3.png",
  },
  {
    label: "Save to Database",
    description:
      "After analysis, the structured data and insights are saved to a DynamoDB database. This step ensures that all information is securely stored and can be easily retrieved for future reference. The database acts as a central repository for all complaints, enabling efficient management and tracking of each case. Saving the data also allows for historical analysis and reporting.",
    image: "/images/step4.png",
  },
  {
    label: "Generate Response",
    description:
      "Based on the analysis, the system generates a response letter in .docx format. This letter addresses the customer’s complaint, outlines the findings, and provides a resolution or next steps. The generated document is tailored to each specific case, ensuring that all relevant points are covered and that the response is clear and professional. This step automates the creation of the initial draft, saving time for the complaints handling team.",
    image: "/images/step5.png",
  },
  {
    label: "Review & Send",
    description:
      "In the final step, a member of the complaints handling team reviews the generated response letter. They ensure that all information is accurate, the tone is appropriate, and that the response complies with company policies and regulations. Once reviewed and approved, the response is finalised and sent to the customer. This step adds a human touch to the automated process, ensuring quality and accuracy before communication with the customer.",
    image: "/images/step6.png",
  },
];
