export const AppRoutes = {
  home: "/",
  complaintsTracker: "/complaint-tracker",
  complaintDetails: "/complaint-tracker/:id",
  newComplaint: "/new-complaint",
  complaintGenerator: "/complaint-generator",
  howItWorks: "/how-it-works",
};

export const CrumbNameMapping: { [key: string]: string } = {
  "": "Home",
  "complaint-tracker": "Complaints Tracker",
  "new-complaint": "New Complaint",
  "how-it-works": "How It Works",
};
