import {
    Grid,
    Typography,
    Button,
    Divider,
    Card,
    Box,
    IconButton
} from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { fetchAuthSession } from "aws-amplify/auth";
import { BASE_ENGINE_API_URL } from "../../../api";
import React from "react";
import useNotification from "../../../hooks/useNotification";
import Notification from "../../../components/Notification";

async function retrieveFile(complaintId: string) {
    try {
        var cognitoTokens = (await fetchAuthSession()).tokens;
        let identityToken = cognitoTokens
            ?.idToken
            ?.toString();

        const requestBody = {
            type: "get_response_letter",
            complaint_id: complaintId
        };

        const presignResponse = await fetch(`${BASE_ENGINE_API_URL}/presign`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${identityToken}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(requestBody)
        });

        if (!presignResponse.ok) {
            throw new Error(`Failed to get presigned URL. Status: ${presignResponse.status}`);
        }

        let presignedUrl = await presignResponse.text();
        presignedUrl = presignedUrl.replace(/['"]+/g, ""); // Remove any quotes around the URL
        console.log("Cleaned Presigned URL:", presignedUrl);

        const uploadResponse = await fetch(presignedUrl, {
            method: "GET"
        });

        if (!uploadResponse.ok) {
            const errorText = await uploadResponse.text();
            throw new Error(`Failed to upload file. Status: ${uploadResponse.status}, Response: ${errorText}`);
        }

        return presignedUrl;
    } catch (error) {
        console.error("Error retrieving file:", error);
        return null;
    }
}

interface Props {
    complaintId: string;
    complaint: any;
}

export const Response = ({ complaintId, complaint }: Props) => {
    const { notification, showNotification, closeNotification } = useNotification();

    const handleUploadClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // Prevent the default button action  
        showNotification('Downloading response letter...', 'info');
        const url = await retrieveFile(complaintId);
        console.log(url)

        if (url) {
            // Create an anchor element  
            const a = document.createElement('a');
            a.href = url;

            // Optional: Set the download attribute with a default filename  
            a.download = 'testfile.docx';

            // Append the anchor to the document body  
            document.body.appendChild(a);

            // Programmatically click the anchor to trigger the download  
            a.click();

            // Remove the anchor from the document  
            document.body.removeChild(a);
        }
    };
    return (
        <>
            <Grid container
                justifyContent="center"
                alignItems="center">
                <Grid item md={12} marginLeft={'2px'}>
                    <Card
                        variant="outlined"
                        style={{
                            padding: '24px'
                        }}>
                        <Grid item md={12} >
                            <Box display={'flex'} justifyContent={'space-between'} pb={1}>
                            <Typography variant="h6" fontWeight="bold" gutterBottom display={"flex"} flexDirection={"row"} alignItems={"center"}>Final Response Letter <AutoAwesomeIcon color="primary" fontSize="inherit" sx={{ml: "4px"}}/></Typography>
                            <Button
                                size="small"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={< FileDownloadIcon />}
                                onClick={handleUploadClick}>
                                Download .docx
                            </Button>
                            </Box>
                            <Divider sx={{ mb: 2 }} />
                        </Grid>
                        <Box>
                            <div dangerouslySetInnerHTML={{ __html: complaint?.complaint_html_frl }}></div>

                        </Box>
                        <Grid item md={12} mt={2} container justifyContent="flex-start" paddingTop={2}>
                            <Button
                                size="small"
                                role={undefined}
                                variant="contained"
                                tabIndex={-1}
                                startIcon={< FileDownloadIcon />}
                                onClick={handleUploadClick}>
                                Download .docx
                            </Button>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            <Notification
                open={notification.open}
                message={notification.message}
                severity={notification.severity}
                onClose={closeNotification}
            /></>
    );
}
