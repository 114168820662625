import {createTheme} from '@mui/material/styles';

export const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#415385"
        },
        text: {
            primary: "#252525"
        },
        background: {
            default: "#fff"
        }
    },
    typography: {
        fontSize: 14,
        fontFamily: [
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        h1: {
            fontSize: "2.5rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h2: {
            fontSize: "2rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h3: {
            fontSize: "1.75rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h4: {
            fontSize: "1.5rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h5: {
            fontSize: "1.25rem",
            fontWeight: 700,
            letterSpacing: -0.7
        },
        h6: {
            fontSize: "1.25rem",
            fontWeight: 600,
            letterSpacing: -0.7
        },

    }
})