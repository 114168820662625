import React, { useState } from 'react';
import { Typography, SpeedDial, SpeedDialAction, SpeedDialIcon, Box, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { SubmitNotes } from '../../new-complaint/components/SubmitNotes';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import { addNotesToComplaint } from '../../../api'; // Import the updateComplaint function

interface CaseSpeedDialProps {
  complaint: any;
  showNotification: (message: string, severity: "info" | "success" | "warning" | "error") => void;
  onAddNotesSuccess: () => void
}

const CaseSpeedDial: React.FC<CaseSpeedDialProps> = ({ complaint, showNotification, onAddNotesSuccess }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpenDialog = () => setOpenDialog(true);
  const handleCloseDialog = () => setOpenDialog(false);

  const handleNotesSubmit = async (notes: string) => {
    setLoading(true);
    showNotification('Submitting notes...', 'info');

    try {
      const updatedComplaint = {
        complaint_id: complaint?.complaint_id,
        notes_update: true,
        content: notes
      };

      await addNotesToComplaint(updatedComplaint);
      onAddNotesSuccess();
      handleCloseDialog();
    } catch (error) {
      console.error('Error submitting notes:', error);
      showNotification('Failed to submit notes.', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <SpeedDial
        ariaLabel="Add Note"
        sx={{ position: 'fixed', bottom: 32, right: 32 }}
        icon={<SpeedDialIcon />}
      >
        <SpeedDialAction
          icon={<NoteAddIcon />}
          tooltipTitle="Add a Case Note"
          onClick={handleOpenDialog}
        />
        <SpeedDialAction
          icon={<AutoModeIcon />}
          tooltipTitle="Re-process case (coming soon)"
          onClick={handleOpenDialog}
        />
      </SpeedDial>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
        <Typography variant="h3" gutterBottom>
          Add a Case Note
        </Typography>
        <Typography variant="body2" gutterBottom>
          Please provide as much detail as possible as this will help both the
          AI model as well as the Complaints Handling team.
        </Typography>
          <SubmitNotes loading={loading} onNotesSubmit={handleNotesSubmit} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CaseSpeedDial;