import { Authenticator, Image, View, useTheme } from "@aws-amplify/ui-react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Box, ThemeProvider, Typography } from "@mui/material";

import "./assets/styles/base.css";
// import "./assets/styles/heading.css";
import "./assets/styles/typography.css";
import "./App.css";

import { defaultTheme } from "./core/theme";
import Home from "./pages/home/home";
import ComplaintDetails from "./pages/complaint-details/ComplaintDetails";
import ComplaintsTracker from "./pages/complaints-tracker/ComplaintsTracker";
import NewComplaint from "./pages/new-complaint/NewComplaint";
import ComplaintGenerator from "./pages/complaint-generator/ComplaintGenerator";
import HowItWorks from "./pages/how-it-works/HowItWorks";

import LogoImage from "./assets/images/PWClogo-colour.svg";

import { AppRoutes } from "./routes";
import Layout from "./pages/layout";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path={AppRoutes.complaintsTracker} element={<ComplaintsTracker />} />
      <Route path={AppRoutes.complaintDetails} element={<ComplaintDetails />} />
      <Route path={AppRoutes.newComplaint}  element={<NewComplaint />} />
      <Route path={AppRoutes.complaintGenerator} element={<ComplaintGenerator />} />
      <Route path={AppRoutes.howItWorks} element={<HowItWorks />} />
      
    </Route>
  )
);

export default function App() {
  const { tokens } = useTheme();
  const components = {
    Header() {
      return (
        <View textAlign="centre" padding={tokens.space.large}>
          <Box>
            <Image
              src={LogoImage}
              alt="PWC"
              width="64px"
            />
          </Box>
          <Box>
          <Typography variant="h1" mt={2}>Complaints Intelligence</Typography>
          </Box>
        </View>
      );
    },
    Footer() {
      const { tokens } = useTheme();
  
      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Typography variant="caption">
            &copy; 2024 PricewaterhouseCoopers UK. All Rights Reserved.
          </Typography>
        </View>
      );
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Authenticator components={components} hideSignUp>
        <RouterProvider router={router} />
      </Authenticator>
    </ThemeProvider>
  );
}
