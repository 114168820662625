import React, { useState } from 'react';
import { Box, Grid, Typography, Stepper, Step, StepLabel, StepContent, Button, Paper } from '@mui/material';
import { Steps } from './constant';

const HowItWorks: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Box>
      <Typography variant="h4" component="h1" gutterBottom align="center">
        How Complaints Intelligence works
      </Typography>

      <Stepper activeStep={activeStep} orientation="vertical">
        {Steps?.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Box sx={{ mb: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography>{step.description}</Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box
                      component="img"
                      sx={{
                        height: 'auto',
                        maxWidth: '100%',
                        overflow: 'hidden',
                        width: '100%',
                      }}
                      src={step.image}
                      alt={step.label}
                    />
                  </Grid>
                </Grid>
                <div>
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    {index === Steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                  <Button
                    disabled={index === 0}
                    onClick={handleBack}
                    sx={{ mt: 1, mr: 1 }}
                  >
                    Back
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>

      {activeStep === Steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>All steps completed - you're finished</Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
};

export default HowItWorks;