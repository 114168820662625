import {
    Alert,
    Card,
    CardContent,
    Typography,
    Grid,
    Chip,
    Divider,
    Tooltip,
    Stepper,
    Step,
    StepLabel,
    Box,
    StepIconProps
} from "@mui/material";
import { CheckCircle, Pending as StepIcon } from '@mui/icons-material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { styled } from '@mui/system';
import { AgeChip } from "../../../components";
import { getStatusColor } from "../constants";
import BulletPointList from "../../../components/bulletpoints";
interface Props {
    complaint: any;
}

const steps = [
    "Attach file",
    "Analysis",
    "Complexity Check",
    "Decisioning",
    "Redress",
    "FRL Generation",
    "Ready for Review"
];

const getStatusIndex = (status: string): number => {
    const statusMap: {
        [key: string]: number
    } = {
        'new': 0,
        'analysis': 1,
        'complexity check': 2,
        'decisioning': 3,
        'redress': 4,
        'frl generation': 5,
        'ready for review': 7
    };

    return statusMap[status.toLowerCase()] ?? 0;
};

const CustomStepIconRoot = styled('div')<{
    ownerState: {
        active?: boolean;
        completed?: boolean;
    }
}>(({ theme, ownerState }) => ({
    color: ownerState.completed
        ? theme.palette.success.main
        : theme.palette.action.disabled,
    '& .MuiStepIcon-active': {
        color: theme.palette.primary.main
    }
}));

const CustomStepIcon: React.FC<StepIconProps> = (props) => {
    const { active, completed, className } = props;

    return (
        <CustomStepIconRoot
            sx={{
                padding: "0",
                height: "1.5em"
            }}
            ownerState={{
                completed,
                active
            }}
            className={className}>
            {completed
                ? <CheckCircle />
                : <StepIcon
                    className={active
                        ? 'MuiStepIcon-active'
                        : ''} />}
        </CustomStepIconRoot>
    );
};

interface ComplaintStepperProps {
    complaint_status: string;
}

export const ComplaintStepper: React.FC<ComplaintStepperProps> = ({ complaint_status }) => {
    const activeStep = getStatusIndex(complaint_status);

    return (
        <Box px={4} sx={{
            width: '100%'
        }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export const CaseDetails = ({ complaint }: Props) => {
    return (
        <Grid container spacing={3} justifyContent="space-between">
            {/* attachment alert */}
            <Grid item xs={12} my={1}>
                {complaint.complaint_status === "New" && (
                    <Alert severity="warning">Case requires a supporting file attached to begin processing.</Alert>
                )}
            </Grid>
            {/* stepper */}
            <Grid item xs={12} mb={1}>
                <ComplaintStepper complaint_status={complaint.complaint_status} />
            </Grid>
            {/* case details main sections */}
            <Grid item xs={12}>
                <Card
                    variant="outlined"
                    style={{
                        padding: '0px'
                    }}>
                    <CardContent>
                        <Typography variant="h6" gutterBottom>
                            Case Details
                        </Typography>
                        <Divider sx={{
                            mb: 2
                        }} />
                        <Grid container spacing={2} paddingBottom={1}>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <Typography variant="subtitle2" fontWeight="bold">Processing stage:</Typography>
                                <Chip
                                    label={complaint.complaint_status}
                                    size="small"
                                    color={getStatusColor(complaint.complaint_status)} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <Typography variant="subtitle2" fontWeight="bold">Case ID:</Typography>
                                <Typography variant="body2">{complaint.complaint_id}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <Typography variant="subtitle2" fontWeight="bold">Created:</Typography>
                                <AgeChip dateCreated={complaint?.date_created} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                                <Typography variant="subtitle2" fontWeight="bold" gutterBottom display={"flex"} flexDirection={"row"} alignItems={"center"}>Category: <AutoAwesomeIcon color="primary" fontSize="inherit" sx={{ml: "4px"}}/></Typography>
                                <Tooltip title={complaint.complaint_categorisation.Analysis}>
                                    <Chip
                                        label={complaint.complaint_categorisation.Decision || "Pending"}
                                        size="small"
                                        color="default" />
                                </Tooltip>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} lg={2}>
                            <Typography variant="subtitle2" fontWeight="bold" gutterBottom display={"flex"} flexDirection={"row"} alignItems={"center"}>Root Cause(s): <AutoAwesomeIcon color="primary" fontSize="inherit" sx={{ml: "4px"}}/></Typography>
                                {complaint.complaint_categorisation
                                    ?.RootCause
                                    ?.length
                                    ? (complaint.complaint_categorisation.RootCause.map((rootcause: string, index: number) => (<Chip key={index} label={rootcause} size="small" />)))
                                    : (
                                        <Chip label={"Pending"} size="small" />
                                    )}
                            </Grid>
                        </Grid>
                        <Divider sx={{
                            mb: 2
                        }} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <Typography variant="subtitle2" fontWeight="bold">Customer ID:</Typography>
                                <Typography variant="body2">{complaint.customer_id}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <Typography variant="subtitle2" fontWeight="bold">Name:</Typography>
                                <Typography variant="body2">
                                    {complaint.customer_details.customer_first_name + " " + complaint.customer_details.customer_last_name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <Typography variant="subtitle2" fontWeight="bold">Address:</Typography>
                                <Typography variant="body2">
                                    {complaint.customer_details.customer_address_street}
                                    <br /> {complaint.customer_details.customer_address_town}
                                    <br /> {complaint.customer_details.customer_postcode}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <Typography variant="subtitle2" fontWeight="bold">Phone:</Typography>
                                <Typography variant="body2">{complaint.customer_details.customer_phone}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <Typography variant="subtitle2" fontWeight="bold">Email:</Typography>
                                <Typography variant="body2">{complaint.customer_details.customer_email}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={2}>
                                <Typography variant="subtitle2" fontWeight="bold">Date of Birth:</Typography>
                                <Typography variant="body2">{complaint
                                    ?.customer_details.customer_dob}</Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} container spacing={3}>
                <Grid item xs={12} md={4}>
                    <Card
                        variant="outlined"
                        style={{
                            padding: '8px',
                            minHeight: '300px'
                        }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom display={"flex"} flexDirection={"row"} alignItems={"center"}>
                                Recommended next steps <AutoAwesomeIcon color="primary" fontSize="inherit" sx={{ml: "4px"}}/>
                            </Typography>
                            <Divider
                                sx={{
                                    mb: 2
                                }} /> {complaint
                                    .notes
                                    .map((element: any, index: any) => (
                                        <Grid key={index} item xs={12}>
                                            <Typography variant="body2"><strong>Note {index + 1}</strong>: {element}</Typography>
                                            {/* <Typography variant="body2"></Typography> */}
                                        </Grid>
                                    ))}
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card
                        variant="outlined"
                        style={{
                            padding: '8px',
                            minHeight: '300px'
                        }}>
                        <CardContent>
                        <Typography variant="h6" gutterBottom display={"flex"} flexDirection={"row"} alignItems={"center"}>
                               Case Summary <AutoAwesomeIcon color="primary" fontSize="inherit" sx={{ml: "4px"}}/>
                            </Typography>
                            <Divider
                                sx={{
                                    mb: 2
                                }} />
                            <BulletPointList
                                paragraph={complaint.complaint_summary && complaint.complaint_summary.Summary
                                    ? complaint.complaint_summary.Summary
                                    : 'TBA'}
                                listStyle="bullet" />
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Card
                        variant="outlined"
                        style={{
                            padding: '8px',
                            minHeight: '300px'
                        }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                Case Notes
                            </Typography>
                            <Divider
                                sx={{
                                    mb: 2
                                }} /> {complaint
                                    .notes
                                    .map((element: any, index: any) => (
                                        <Grid key={index} item xs={12}>
                                            <Typography variant="body2"><strong>Note {index + 1}</strong>: {element}</Typography>
                                            {/* <Typography variant="body2"></Typography> */}
                                        </Grid>
                                    ))}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
};