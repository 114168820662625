import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Button,
  Divider,
  Card,
  TextField,
  LinearProgress,
} from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import { downloadFileFromS3 } from '../../api';
import Notification from "../../components/Notification";
import useNotification from "../../hooks/useNotification";

const Response: React.FC = () => {
  const [complaintId, setComplaintId] = useState<string>('');
  const [prompt, setPrompt] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const { notification, showNotification, closeNotification } = useNotification();

  const handleRequestFile = async () => {
    setLoading(true);
    showNotification('Generating complaint, please do not leave this page.', 'info');
    setError(null);

    try {
      const message = await downloadFileFromS3(complaintId, prompt);
      if (message) {
        console.log(message);
        showNotification(message, 'info');
      }
      setLoading(false);
    } catch (err) {
      setError('Failed to download file');
      showNotification('Failed to download file', 'error');
      setLoading(false);
    }
  };

  return (
    <Grid container alignItems="center">
      <Grid item md={6} marginLeft="2px">
        <Card variant="outlined" style={{ padding: '24px' }}>
          <Grid item md={12}>
            <Typography variant="h6" gutterBottom>
              Generate a sample call recording
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Typography variant="body2" gutterBottom>
              This feature allows you to generate a new call recording for demo purposes. Note that it can take up to a minute to generate the call recording.
            </Typography>
          </Grid>
          <Grid container spacing={1} paddingTop={3} justifyContent="space-between">
            <Grid item>
            <TextField
              label="Complaint ID"
              value={complaintId}
              onChange={(e) => setComplaintId(e.target.value)}
              fullWidth
              placeholder="Enter 'demo' for a demo complaint. ID not required."
              size="small"
                          />
            <TextField
              label="Prompt"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              margin="normal"
              size='small'
              multiline
              minRows={3}
              fullWidth
            />
            <Button
              size="medium"
              variant="contained"
              startIcon={<MicIcon />}
              onClick={handleRequestFile}
              disabled={loading || !complaintId || !prompt}
              style={{ marginTop: '16px' }}
            >
              {loading ? 'Generating...' : 'Generate'}
            </Button>
          </Grid>
          </Grid>
          {loading && <LinearProgress variant='indeterminate' style={{ marginTop: '16px' }} />}
          {error && <Typography color="error">{error}</Typography>}
        </Card>
      </Grid>
      <Notification
        open={notification.open}
        message={notification.message}
        onClose={closeNotification}
        severity={notification.severity} />
    </Grid>
  );
};

const ComplaintGenerator: React.FC = () => {
  return (
    <>
      <Typography variant="h1" marginBottom="24px" gutterBottom>
        Complaint Generator
      </Typography>
      <Response />
    </>
  );
};

export default ComplaintGenerator;