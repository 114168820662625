import React from "react";
import {
    Box,
    Chip,
    Card,
    CardContent,
    Typography,
    Divider,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import BulletPointList from "../../../components/bulletpoints";

interface Props {
    complaint : any;
}

const getDecisionColor = (decision : string | number) => {
    // Convert the decision to a number if it's a string representing a number
    const numericDecision = typeof decision === 'string' && !isNaN(Number(decision))
        ? Number(decision)
        : decision;

    // Check if the decision is a number and greater than 0
    if (typeof numericDecision === 'number' && numericDecision > 0) {
        return 'warning';
    }

    // Convert to lowercase string for comparison
    const decisionString = decision
        .toString()
        .toLowerCase();

    switch (decisionString) {
        case "uphold":
            return "success";
        case "apology":
            return "info";
        case "rejected":
            return "error";
        default:
            return "default";
    }
};

export const Findings = ({complaint} : Props) => {
    console.log(complaint);
    return (
        <Grid container spacing={3} justifyContent="space-between">
            <Grid item xs={12}>
                <Card variant="outlined">
                    <Box p={2}>
                    <Typography variant="h6" fontWeight="bold" gutterBottom display={"flex"} flexDirection={"row"} alignItems={"center"}>Complaint Points <AutoAwesomeIcon color="primary" fontSize="inherit" sx={{ml: "4px"}}/></Typography>
                        <Typography variant="body2" gutterBottom>
                            The following complaint points have been identified:
                        </Typography>

                    </Box>

                    <Divider/>
                    <CardContent>

                        <div>
                            {complaint.complaint_decision && complaint.complaint_decision.length > 0
                                ? (complaint.complaint_decision.map((decision : any, index : number) => (
                                    <Accordion key={index} disableGutters elevation={0} square>
                                        <AccordionSummary
                                            expandIcon={< ExpandMoreIcon />}
                                            aria-controls={`panel${index}-content`}
                                            id={`panel${index}-header`}>
                                            <Box display="flex" flexDirection="column" width="100%">
                                                <Box display="flex" justifyContent="space-between">
                                                    <Typography variant="subtitle1" fontWeight={500}>
                                                        {decision.complaint_point || 'TBA'}
                                                    </Typography>
                                                    <Box>
                                                        <Chip
                                                            sx={{
                                                            mr: 2
                                                        }}
                                                            label={'Decision: ' + (decision.Decision || 'TBA')}
                                                            color={getDecisionColor(decision.Decision || '')}/>
                                                        <Chip
                                                            label={'Redress: ' + (decision.Redress
                                                            ?.Decision || 'TBA')}
                                                            color={getDecisionColor(decision.Redress
                                                            ?.Decision || '')}/>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                width="100%"
                                                px={2}
                                                mb={2}
                                                borderLeft={1}>
                                                <Box mb={2}>
                                                    <Typography variant="subtitle2">Analysis:</Typography>
                                                    <Typography variant="body2">
                                                        {decision.Analysis
                                                            ? <BulletPointList paragraph={decision.Analysis || 'TBA'} listStyle="bullet"/>
                                                            : 'TBA'}
                                                    </Typography>
                                                </Box>
                                                <Divider variant="middle"/>
                                                <Box mt={2}>
                                                    <Typography variant="subtitle2">Redress Rationale:</Typography>
                                                    {decision.Redress
                                                        ?.Analysis
                                                            ? <BulletPointList
                                                                    paragraph={decision.Redress.Analysis || 'TBA'}
                                                                    listStyle="bullet"/>
                                                            : 'TBA'}
                                                </Box>
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                )))
                                : (
                                    <Typography>No complaint decisions available.</Typography>
                                )}
                        </div>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12}>
                <Card
                    variant="outlined"
                    style={{
                    padding: '8px'
                }}>
                    <CardContent>
                    <Typography variant="h6" fontWeight="bold" gutterBottom display={"flex"} flexDirection={"row"} alignItems={"center"}>Summary Redress Value <AutoAwesomeIcon color="primary" fontSize="inherit" sx={{ml: "4px"}}/></Typography>
                        <Typography gutterBottom>
                            To be added (WIP)
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};