import { Box, Grid, Card, CardContent, Typography } from "@mui/material";
import { CustomerData } from "../../../interfaces";

interface Props {
  data: CustomerData;
}

export function CustomerDetailsContent({ data }: Props) {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
    >
      <Card variant="outlined" style={{ padding: "16px" }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Customer details found:
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">Customer ID:</Typography>
              <Typography variant="body2">{data?.customer_id}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">Customer Name:</Typography>
              <Typography variant="body2">
                {data?.customer_first_name + " " + data?.customer_last_name}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">Customer Address:</Typography>
              <Typography variant="body2">
                {data?.customer_address_street +
                  ", " +
                  data?.customer_address_town +
                  ", " +
                  data?.customer_postcode}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">
                Customer Phone Number:
              </Typography>
              <Typography variant="body2">{data?.customer_phone}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">Customer Email:</Typography>
              <Typography variant="body2">{data?.customer_email}</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography variant="subtitle1">Date of Birth:</Typography>
              <Typography variant="body2">{data?.customer_dob}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}
