import { useState } from "react";
import * as React from "react";
import {
  Home,
  PendingActions,
  Segment,
  Settings,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";
import {
  Box,
  Toolbar,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Divider
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import { signOut } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import {fetchUserAttributes} from "aws-amplify/auth";

const pagesComplaintsHandling = [
  { name: "Create new complaint", href: "/new-complaint" },
  { name: "Complaints Tracker", href: "/complaint-tracker" },
  { name: "My Flagged Cases", href: "#flaggedcases" },
  // Add more pages as needed
];
const pagesSettings = [
  { name: "Settings", href: "#settings" },
  { name: "Help", href: "#help" },
  { name: "Logout", href: "" },
  // Add more pages as needed
];

const devTools = [
  { name: "Complaint Generator", href: "/complaint-generator" },
  // Add more items as needed
];

export const AppSideBarWidth: number = 300;

export const AppSideBar = () => {
  const navigate = useNavigate();
  const [openSections, setOpenSections] = useState<{
    [key: string]: boolean;
  }>({ home: false, submenu1: true, submenu2: false, submenu3: false });

  const toggleCollapse = (section: string) => {
    setOpenSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section],
    }));
  };

  async function logoutUser() {
    await signOut();
    navigate("/", { replace: true });
  }


  const [welcomeMessage,
    setWelcomeMessage] = React.useState("");

  React.useEffect(() => {
    const getCurrentUsername = async() => {
        try {
            const attributes = await fetchUserAttributes();
            const first_name = attributes.given_name
            const last_name = attributes.family_name
            setWelcomeMessage(`Welcome, ${first_name} ${last_name}!`);
        } catch (error) {
            console.log("Error getting current user:", error);
        }
    };

    getCurrentUsername();
  }, []);


  //   React.useEffect(() => {
  //     const getCurrentUsername = async () => {
  //       try {
  //         const user = await getCurrentUser();
  //         setNormalStringUsername(String(user.username));
  //       } catch (error) {
  //         console.log("Error getting current user:", error);
  //       }
  //     };

  //     getCurrentUsername();
  //   }, []);

  return (
    <Drawer
      variant="permanent"
      sx={{
        bgcolor: "#FFFFFF",
        borderRight: "none",
        width: AppSideBarWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paperAnchorDockedLeft`]: {
          borderRight: 0,
        },
        [`& .MuiDrawer-paper`]: {
          width: AppSideBarWidth,
          boxSizing: "border-box",
        },
      }}
    >
      
      <Toolbar />
      <Box
        sx={{
          overflow: "auto",
        }}
      >
        <List
          component="nav"
          sx={{
            padding: "8px",
            margin: "32px 20px",
            height: "auto",
            bgcolor: "#F3F3F3",
            boxShadow: "0 0 0 0",
            color: "#252525",
            borderRadius: "8px",
          }}
        >
          <Box p={1}>
          <Typography
              variant="body2"
              gutterBottom
              sx={{
              letterSpacing: 0,
          }}>
              {welcomeMessage}
          </Typography>
          </Box>
          
          <Divider />
          
          {/* Main menu items */}

          {/* home */}
          <ListItemButton href="/">
            <ListItemIcon
              sx={{
                minWidth: "0",
                paddingRight: "10px",
              }}
            >
              <Home
                sx={{
                  color: "#252525",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Home"
              primaryTypographyProps={{
                style: {
                  fontSize: "0.9em",
                },
              }}
            />
          </ListItemButton>

          {/* complaints handling sub menu section */}
          <ListItemButton
            onClick={() => toggleCollapse("submenu1")}
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "0",
                paddingRight: "10px",
              }}
            >
              <PendingActions
                sx={{
                  color: "#252525",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Complaints Handling"
              primaryTypographyProps={{
                style: {
                  fontSize: "0.9em",
                },
              }}
            />
            {openSections.submenu1 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSections.submenu1} timeout="auto" unmountOnExit>
            <List
              component="div"
              disablePadding
              sx={{
                "& .MuiListItemButton-root": {
                  pl: 2,
                  ml: 5,
                  mr: 2,
                  fontSize: "0.9em",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#415385",
                    color: "#fff",
                  },
                },
                "& .MuiListItemText-root": {
                  marginTop: "2px",
                  marginBottom: "2px",
                },
              }}
            >
              {/* map submenu items */}
              {pagesComplaintsHandling.map((page) => (
                <ListItemButton key={page.name} href={page.href}>
                  <ListItemText
                    primary={page.name}
                    primaryTypographyProps={{
                      style: {
                        fontSize: "0.9em",
                      },
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>

          {/* generic submenu */}
          <ListItemButton
            onClick={() => toggleCollapse("submenu2")}
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "0",
                paddingRight: "10px",
              }}
            >
              <Segment
                sx={{
                  color: "#252525",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Developer Tools"
              primaryTypographyProps={{
                style: {
                  fontSize: "0.9em",
                },
              }}
            />
            {" "}
            {openSections.submenu2 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>

          <Collapse in={openSections.submenu2} timeout="auto" unmountOnExit>
          <List
              component="div"
              disablePadding
              sx={{
                "& .MuiListItemButton-root": {
                  pl: 2,
                  ml: 5,
                  mr: 2,
                  fontSize: "0.9em",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#415385",
                    color: "#fff",
                  },
                },
                "& .MuiListItemText-root": {
                  marginTop: "2px",
                  marginBottom: "2px",
                },
              }}
            >
              {/* map api functions to list item buttons */}
              {devTools.map((item) => (
                <ListItemButton key={item.name} href={item.href}>
                <ListItemText
                  primary={item.name}
                  primaryTypographyProps={{
                    style: {
                      fontSize: "0.9em",
                    },
                  }}
                />
              </ListItemButton>
              ))}
            </List>
          </Collapse>

          {/* submenu3 */}
          <ListItemButton
            onClick={() => toggleCollapse("submenu3")}
            sx={{
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "0",
                paddingRight: "10px",
              }}
            >
              <Settings
                sx={{
                  color: "#252525",
                }}
              />
            </ListItemIcon>
            <ListItemText
              primary="Settings & Help"
              primaryTypographyProps={{
                style: {
                  fontSize: "0.9em",
                },
              }}
            />{" "}
            {openSections.submenu3 ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSections.submenu3} timeout="auto" unmountOnExit>
          <List
              component="div"
              disablePadding
              sx={{
                "& .MuiListItemButton-root": {
                  pl: 2,
                  ml: 5,
                  mr: 2,
                  fontSize: "0.9em",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "#415385",
                    color: "#fff",
                  },
                },
                "& .MuiListItemText-root": {
                  marginTop: "2px",
                  marginBottom: "2px",
                },
              }}
            >
              {/* map submenu items */}
              {pagesSettings.map((page) => (
                <ListItemButton
                  key={page.name}
                  href={page.href}
                  onClick={logoutUser}
                >
                  <ListItemText
                    primary={page.name}
                    primaryTypographyProps={{
                      style: {
                        fontSize: "0.9em",
                      },
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          </Collapse>
        </List>
      </Box>
    </Drawer>
  );
};
