import { AppRoutes } from "../../../routes";
import { SvgIconComponent } from "@mui/icons-material";
import PostAddSharpIcon from '@mui/icons-material/PostAddSharp';
import PreviewSharpIcon from '@mui/icons-material/PreviewSharp';
import LocalLibrarySharpIcon from '@mui/icons-material/LocalLibrarySharp';



export interface Option {
  title: string;
  description: string;
  buttonLabel: string;
  navPath?: string;
  icon?: SvgIconComponent;
}

export const Options: Option[] = [
  {
    title: "Track existing complaints",
    description:
      "Use the Complaint Tracker to get single glass view of all open complaints",
    buttonLabel: "View Complaints",
    navPath: AppRoutes.complaintsTracker,
    icon: PreviewSharpIcon
  },
  {
    title: "Open a new case",
    description:
      "Use this tool to simulate opening a new complaint case for demo purposes.",
    buttonLabel: "Open new case",
    navPath: AppRoutes.newComplaint,
    icon: PostAddSharpIcon
  },
  
  {
    title: "Learn about the platform",
    description:
      "Learn how the solution works and the role generative AI plays in the process.",
    buttonLabel: "Learn more",
    navPath: AppRoutes.howItWorks,
    icon: LocalLibrarySharpIcon
  },
  
];
